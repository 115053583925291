@import 'foundation/foundation';
@import "layout/variable";

.sec-error {
	display: flex;
	align-items: center;
	justify-content: center;
	.sec-title {
		margin-bottom: 0; }

	.title-h2 {
		font-size: rem-calc(80);
		color: $green;
		font-family: Poppins,sans-serif;
		@include breakpoint(medium down) {
			font-size: rem-calc(40); } }

	.title-h4 {
		text-align: center;
		line-height: 2;
		margin: rem-calc(60) 0 rem-calc(70) 0;
		@include breakpoint(medium down) {
			margin: rem-calc(30) 0 rem-calc(40) 0; } }

	&__veg-01, &__veg-02 {
		display: block;
		position: absolute; }
	&__veg-01 {
		top: 11%;
		left: 0%;
		width: 17rem;
		@include breakpoint(1200px down) {
			top: -1%;
			width: 12rem; }
		@include breakpoint(medium down) {
			top: -5%;
			width: 8rem; }
		@include breakpoint(320px down) {
			top: -10%; } }
	&__veg-02 {
		top: 30%;
		right: -10px;
		width: 17rem;
		@include breakpoint(1200px down) {
			width: 12rem; }
		@include breakpoint(medium down) {
			top: 32%;
			width: 8rem; }
		@include breakpoint(320px down) {
			top: 36%; } } }
.sec-sale {
	margin: 0 0 rem-calc(40) 0;
	@include breakpoint(medium) {
		margin: 0 0 rem-calc(70) 0; }
	.title-h2 {
		@extend %h3; }
	.sec-title {
		margin-bottom: rem-calc(20); } }
