// color
$black: #000;
$black01: #e4e4e4;
$white: #fff;
$greenLight: #ecf4eb;
$greenMiddle: #c8dfc3;
$green: #499537;
$green-dark: #4d7b6f;
$green-dark2: #4e7b70;
$red: #e0363d;
$brown: #7f4811;
$dark-brown: #483527;
$orange: #efbf4d;
$turmeric: #bca40e;
$tiffany: #469983;
$blue: #6eaabf;
$pink: #ee2a7b;

$error: #d02e35;

$facebook: #1877f2;

$greenL01: rgba(#499537, 0.08);
$greenL02: rgba(#499537, 0.06);
$greenL03: rgba(#499537, 0.03);
$greenL04: rgba(#499537, 0.1);
$greenO_15: #e4eee1;
$greenO_3: #7fb573;

$textColor: #29211b;
$textColorO_1: rgba($textColor, 0.1);
$textColorO_3: rgba($textColor, 0.3);
$textColorO_7: rgba($textColor, 0.7);
$textColorO_9: rgba($textColor, 0.9);

$borderColor: #b6afaa;
$menuBorderColor: #483628;

$breadcrumb: #918e8a;
$chapter: #94908d;
$shoppingBorder: #b2b2b2;
$sealColor: #a2a2a2;

$scrollbar: rgba($textColor , 0.2);

$discountCard: #f9f9f9;

$lightGrey: #fcfcfc;
$darkGrey: #262626;
$darkGrey02: #393939;

// Font
$fontFamily: 'Poppins', 'Noto Sans TC', sans-serif;
$NotoSansTC: sans-serif, "Microsoft JhengHei", 'Noto Sans TC';
$Poppins: 'Poppins', sans-serif;

// content max-width
$containerWidth: 1200px;
$paddingSpace: 20px;
$containerWidthPadding: $containerWidth + $paddingSpace * 2;
$btnGutter: 0.5625rem;

$contPdX: 50;
$contPdX_l: 130;

// 依需求設定內容最大寬度
@mixin setCustomContentW($width) {
	padding-left: rem-calc($paddingSpace);
	padding-right: rem-calc($paddingSpace);
	@include breakpoint(pad) {
		padding-left: rem-calc($contPdX);
		padding-right: rem-calc($contPdX);
		max-width: rem-calc($width + $contPdX_l * 2); }
	@include breakpoint(xlarge) {
		padding-left: rem-calc($contPdX_l);
		padding-right: rem-calc($contPdX_l);
		max-width: rem-calc($width + $contPdX_l * 2); } }

@mixin setContentW($width, $pad) {
	padding-left: rem-calc($pad);
	padding-right: rem-calc($pad);
	max-width: rem-calc($width + $pad * 2); }

// 介於 $start 到 $end 的 RWD
// 以 Foundation media query 的 mixin 為基底使用
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content; } }


// 依需求設定 grid:左右加起來, for 所有
@mixin customGrid($columns, $grid) {
	@include xy-cell-gutters(-$grid);

	& > .cell {
		@include xy-cell-gutters($grid); }

	$grid-columns: $columns;

	@each $key, $val in $breakpoints {
		@include breakpoint($key) {
			@for $i from 1 through $grid-columns {
				& > .#{$key}-#{$i} {
					@include xy-cell-size($i, $grid, margin, null, false); } }
			// offset
			// @for $i from 0 through $grid-columns
			// 	& > .#{$key}-offset-#{$i}
 } } }			// 		+xy-cell-offset($i, $grid, margin, $key, false)


// h1 ~ h6
%h1 {
	font-size: rem-calc(28);
	text-align: center;
	color: $textColor;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: rem-calc(5);

	@include breakpoint(pad) {
		font-size: rem-calc(39); }

	@include breakpoint(large) {
		font-size: rem-calc(50); } }

%h1Bold {
	font-size: rem-calc(32);
	font-weight: 700;

	@include breakpoint(pad) {
		font-size: rem-calc(46); }

	@include breakpoint(large) {
		font-size: rem-calc(60); } }

%h1Small {
	font-size: rem-calc(22);
	color: $textColorO_9;
	letter-spacing: rem-calc(1.6);

	@include breakpoint(pad) {
		font-size: rem-calc(27); }

	@include breakpoint(large) {
		font-size: rem-calc(32); } }

%h2 {
	font-size: rem-calc(28);
	text-align: center;
	color: $textColor;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: rem-calc(5);

	@include breakpoint(pad) {
		font-size: rem-calc(39); }

	@include breakpoint(large) {
		font-size: rem-calc(50); } }

%h2Small {
	font-size: rem-calc(22);
	color: $textColorO_9;
	letter-spacing: rem-calc(1.6);

	@include breakpoint(pad) {
		font-size: rem-calc(27); }

	@include breakpoint(large) {
		font-size: rem-calc(32); } }

%h3 {
	font-size: rem-calc(18);
	color: $textColorO_9;
	font-weight: 700;

	@include breakpoint(pad) {
		font-size: rem-calc(21); }

	@include breakpoint(large) {
		font-size: rem-calc(24); } }
%h4 {
	font-size: rem-calc(16);
	color: $textColorO_9;
	font-weight: 400;

	@include breakpoint(pad) {
		font-size: rem-calc(18); }

	@include breakpoint(large) {
		font-size: rem-calc(20); } }

%h5 {
	font-size: rem-calc(16);
	color: $textColorO_9;
	font-weight: 500; }

// title
%title {
	font-size: rem-calc(16);
	color: $textColorO_9;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: rem-calc(5);

	@include breakpoint(pad) {
		font-size: rem-calc(18); }
	@include breakpoint(large) {
		font-size: rem-calc(20); } }

%titleMedium {
	font-size: rem-calc(16);
	color: $textColorO_7;
	font-weight: 400;

	@include breakpoint(pad) {
		font-size: rem-calc(18); } }

%titleSmall {
	font-size: rem-calc(16);
	color: $textColorO_9;
	font-weight: 400; }

// subtitle
%subtitle {
	font-size: rem-calc(16);
	color: $textColorO_9;
	font-weight: 400;
	letter-spancing: rem-calc(0.7);
	line-height: 1.5; }

%small-txt {
	font-size: rem-calc(14);
	font-weight: 300;
	letter-spacing: rem-calc(0.7); }


// 最大寬度
%containerWidth {
	max-width: $containerWidth;
	width: 100%;
	margin-right: auto;
	margin-left: auto; }

// 最大寬度 ＋ padding
%containerWidthPadding {
	max-width: $containerWidthPadding;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: $paddingSpace;
	padding-left: $paddingSpace; }

// image 一般設定
%bgImage {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center; }

// void
%void {
	content: '';
	display: block;
	position: relative; }

// Flex
@mixin fc($justify, $align) {
	display: flex;
	justify-content: $justify;
	align-items: $align; }

// 正方形
@mixin square($width) {
	width: $width;
	height: $width; }


@mixin setAnimate($animateEl) {
    @each $class in $animateEl {
        $i: index($animateEl, $class);
        $i: $i;

        #{$class} {
            opacity: 0;
            transform: translateY(60px);
            transition-duration: 0.7s;
            transition-property: opacity, transform;
            transition-delay: #{$i * 0.5s}; } } }

@mixin setAnimated($animateEl) {
    @each $class in $animateEl {
        #{$class} {
            opacity: 1;
            transform: none; } } }

@mixin slideArrowPos($isTop) {
	transform: translateY(-50%);
	top: calc((100vw - 260px - 60px) / 10 + #{$isTop}px);

	@include breakpoint(xxlarge) {
		top: calc(228px / 2 + #{$isTop}px); }

	@include breakpoint(large down) {
		top: calc((100vw - 60px - 55px) / 10 + #{$isTop}px); }

	@include breakpoint(pad down) {
		top: calc((100vw - 30px - 55px) / 6 + #{$isTop}px); }

	@include breakpoint(medium down) {
		top: calc((100vw - 15px - 55px) / 4 + #{$isTop}px); } }

%greenExpendBottom {
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: .1875rem;
        width: 0%;
        background-color: $green;
        -webkit-transition: .3s all;
        transition: .3s all; } }

%greenExpendBottomHover {
    &::after {
        width: 100%; } }

// card
%card__box-info__type {
	position: relative;
	display: inline-block;
	background-color: $blue;
	color: $white;
	font-size: rem-calc(12);
	font-weight: 400;
	padding: rem-calc(4) rem-calc(11);
	padding-right: rem-calc(15);
	margin-bottom: rem-calc(6);

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 0; }

	&::before {
		top: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 rem-calc(6) rem-calc(10) 0;
		border-color: transparent $white transparent transparent; }

	&::after {
		bottom: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 rem-calc(10) rem-calc(6);
		border-color: transparent transparent $white transparent; } }

%article__box-claim_item {
	font-size: rem-calc(14);
	line-height: 1.5;
	letter-spacing: rem-calc(1.4);
	color: $green;
	@include fc(flex-start, baseline);
	margin-bottom: rem-calc(5);

	.be-icon {
		position: relative;
		top: rem-calc(2);
		&:before {
			font-size: rem-calc(19);
			margin: 0;
			margin-right: rem-calc(8); } } }


// 注意事項
%notice {
	counter-reset: chineseItem;
	list-style: none;

	& > li {
		position: relative;
		display: flex;
		&:before {
			content: counter(chineseItem, decimal) ". ";
			counter-increment: chineseItem;
			padding-right: rem-calc(12);
			flex: 0 0 rem-calc(24);
			min-width: rem-calc(24);
			width: rem-calc(24);

			html.ie & {
				flex: 0 0 rem-calc(12);
				min-width: rem-calc(12);
				width: rem-calc(12); } } }
	&.text_14 {
		font-size: rem-calc(14); }
	&.text_ColorO_9 {
		color: $textColorO_9; } }
